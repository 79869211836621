// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-building-js": () => import("./../../../src/pages/building.js" /* webpackChunkName: "component---src-pages-building-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-type-js": () => import("./../../../src/pages/type.js" /* webpackChunkName: "component---src-pages-type-js" */),
  "component---src-templates-building-js": () => import("./../../../src/templates/building.js" /* webpackChunkName: "component---src-templates-building-js" */),
  "component---src-templates-design-js": () => import("./../../../src/templates/design.js" /* webpackChunkName: "component---src-templates-design-js" */)
}

